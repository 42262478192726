import type { GetServerSideProps, NextPage } from "next";
import {
  ContentModule,
  getGlobals,
  getGraphqlSdk,
  GlobalProps,
  GraphQLResponseError
} from "@src/lib/services/server/contentful";
import type {} from "@src/lib/types";
import type {
  ProductPageQuery,
  ProductPageContentModulesQuery,
  ProductPageContentModules2Query,
  ProductPageQueryVariables,
  ProductPageContentModules3Query
} from "@src/lib/services/server/contentful/generated/graphqlSdk";
import { logError } from "@src/lib/services/server/logger";
import Error from "next/error";
import { filterNull } from "@src/lib/utils";
import "setimmediate";
import {
  ProductLayout,
  ProductContentModules,
  ProductHeroSolution
} from "@src/components";
import { setCacheHeaders } from "@src/lib/utils/pageCache";
import { PageCacheGroups } from "@src/lib/types/fastly";

export type ProductPageParams = {
  slug: string[];
};

export type ProductPageProps = GlobalProps & {
  entry?: ProductPageQuery;
  contentModules?: ProductPageContentModulesQuery;
  contentModules2?: ProductPageContentModules2Query;
  contentModules3?: ProductPageContentModules3Query;
};

export const ProductPage: NextPage<ProductPageProps> = ({
  entry,
  contentModules,
  contentModules2,
  contentModules3,
  ...globalProps
}: ProductPageProps) => {
  if (!entry) {
    return <Error statusCode={500} />;
  }

  const entryItem = entry.productPageCollection
    ? entry.productPageCollection.items[0]
    : null;

  const contentModulesItem =
    contentModules && contentModules.productPageCollection
      ? contentModules.productPageCollection.items[0]
      : null;

  const contentModules2Item =
    contentModules2 && contentModules2.productPageCollection
      ? contentModules2.productPageCollection.items[0]
      : null;

  const contentModules3Item =
    contentModules3 && contentModules3.productPageCollection
      ? contentModules3.productPageCollection.items[0]
      : null;

  if (!entryItem) {
    return null;
  }

  const contentModulesItems = filterNull(
    contentModulesItem?.contentModulesCollection?.items
  );

  const contentModules2Items = filterNull(
    contentModules2Item?.contentModulesCollection?.items
  );

  const contentModules3Items = filterNull(
    contentModules3Item?.contentModulesCollection?.items
  );

  const combinedContentModules = contentModulesItems.map(
    (contentModule, count) => {
      if ("sys" in contentModule) {
        return contentModule;
      } else {
        return {
          ...contentModules2Items[count],
          ...contentModules3Items[count]
        };
      }
    }
  );

  return (
    <ProductLayout entry={entryItem} navigationStyle="white" {...globalProps}>
      <section>
        <ProductHeroSolution
          heading={entryItem.heading || entryItem.title}
          text={entryItem.description}
          image={entryItem.leadImage}
          heroStyle={entryItem.heroStyle?.toLowerCase().split(" ").join("")}
          ctaTitle={entryItem.ctaTitle}
          ctaUrl={entryItem.ctaUrl}
          ctaStyle={entryItem.ctaStyle?.toLowerCase().split(" ").join("")}
        />
        <div>
          <ProductContentModules
            contentModules={combinedContentModules as ContentModule[]}
          />
        </div>
      </section>
    </ProductLayout>
  );
};

export const getServerSideProps: GetServerSideProps<
  ProductPageProps,
  ProductPageParams
> = async context => {
  const { params, preview = false } = context;
  const props: ProductPageProps = {};

  if (!params) {
    return { notFound: true };
  }

  const { slug } = params;

  const urlPath = `${slug.join("/")}`;
  const query: ProductPageQueryVariables = { urlPath, preview };
  const graphqlSdk = getGraphqlSdk(preview);
  const productPageQuery = graphqlSdk.productPage(query);
  const productPageContentModulesQuery =
    graphqlSdk.productPageContentModules(query);
  const productPageContentModules2Query =
    graphqlSdk.productPageContentModules2(query);
  const productPageContentModules3Query =
    graphqlSdk.productPageContentModules3(query);
  const globalsQuery = getGlobals(preview);

  Object.assign(props, await globalsQuery);

  setCacheHeaders(PageCacheGroups.PRODUCTS, context);

  try {
    props.entry = await productPageQuery;
  } catch (e) {
    const error = e as GraphQLResponseError<ProductPageQuery>;
    if (error?.response?.data) {
      props.entry = error.response.data;
    } else {
      logError(error);
      return { props };
    }
  }

  try {
    props.contentModules = await productPageContentModulesQuery;
  } catch (error) {
    logError(error);
    props.contentModules = undefined;
  }

  try {
    props.contentModules2 = await productPageContentModules2Query;
  } catch (error) {
    logError(error);
    props.contentModules2 = undefined;
  }

  try {
    props.contentModules3 = await productPageContentModules3Query;
  } catch (error) {
    logError(error);
    props.contentModules3 = undefined;
  }

  const entryItem = props.entry.productPageCollection
    ? props.entry.productPageCollection.items[0]
    : null;

  if (!entryItem) {
    return { notFound: true };
  }

  return { props };
};

export default ProductPage;
